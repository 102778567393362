import { ADD_INSURANCE_LIST_MODES, ADD_INSURANCE_EXTRA_IDS, ADD_INSURANCE_DEFAULT_TAGS } from "components/AdditionalInsuranceList/constants";
import { FIELD_TYPES } from "constants/fieldTypes";
import { convertCents } from "utils/convertCents";

export const setIds = (required, insurancesIds, insuranceId, packages, packagesIds, availablePackagesIds = [], mode = ADD_INSURANCE_LIST_MODES.USING) => {
  if (required) {
    insurancesIds.push(insuranceId);
  }

  if (packages?.length === 1) {
    if (mode === ADD_INSURANCE_LIST_MODES.USING) {
      if (!required) {
        packages.forEach(({ id: packageId }) => {
          if (packageId < 0) {
            packagesIds.push(packageId);
          }
        });
      } else {
        packagesIds.push(packages?.[0]?.id);
      }
    } else if (mode === ADD_INSURANCE_LIST_MODES.SETTINGS) {
      packagesIds.push(packages?.[0]?.id);
      availablePackagesIds.push(packages?.[0]?.id);
    }
  } else {
    if (mode === ADD_INSURANCE_LIST_MODES.USING) {
      if (!required) {
        packages.forEach(({ id: packageId }) => {
          if (packageId < 0) {
            packagesIds.push(packageId);
          }
        });
      } else {
        packages.forEach(({ id: packageId, is_default }) => {
          if (is_default) {
            packagesIds.push(packageId);
          }
        });
      }
    } else if (mode === ADD_INSURANCE_LIST_MODES.SETTINGS) {
      for (let i = 0; i < packages.length; i++) {
        const {
          id: packagesId,
          is_available
        } = packages[i];

        if (is_available) {
          packagesIds.push(packagesId);

          const otherPackages = packages.slice(i);

          otherPackages.forEach(({ id: packageId }) => availablePackagesIds.push(packageId));

          break;
        }
      }
    }
  }
};

export const getTags = ({ insurances = [] }) => {
  return [...new Set(insurances.reduce((arr, { tags }) => {
    return [...arr, ...tags];
  }, []))];
};

export const removeExtraPackagesId = (ids) => {
  return ids.filter(id => id !== ADD_INSURANCE_EXTRA_IDS.PACKAGE_ID || id < 0);
};

export const removeExtraInsuranceIdFromSelectPackages = (selectedPackagesAdditionalInsurance = {}) => {
  return {
    ...selectedPackagesAdditionalInsurance,
    insurancesIds: selectedPackagesAdditionalInsurance?.insurancesIds.filter(id => id !== ADD_INSURANCE_EXTRA_IDS.INSURANCE_ID)
  };
};

export const getDefaultValues = (tags) => {
  const values = {};

  tags.forEach(tag => values[tag] = "");

  return values;
};

export const resetDefaultValues = (values) => {
  const defaultValues = {};

  Object.keys(values).forEach(key => defaultValues[key] = "");

  return defaultValues;
};


export const setInitialDefaultTags = () => {
  const defaultTags = {};

  for (let key in ADD_INSURANCE_DEFAULT_TAGS) {
    defaultTags[ADD_INSURANCE_DEFAULT_TAGS[key]] = "";
  }

  return defaultTags;
};

/* This function takes the value of all fields of the form and gets the value of only those fields whose names refer to the names of default tags */
export const setDefaultTags = (formInsuranceFields) => {
  const defaultTags = {};
  const defaultTagsValues = Object.values(ADD_INSURANCE_DEFAULT_TAGS);

  for (let key in formInsuranceFields) {
    if (defaultTagsValues.includes(key)) {
      defaultTags[key] = formInsuranceFields[key];
    }
  }

  return defaultTags;
};

export const addEmptyPackageInInsurances = (insurances, mode = ADD_INSURANCE_LIST_MODES.USING) => {

  if (mode === ADD_INSURANCE_LIST_MODES.USING) {
    return insurances.map((insurance, index) => {
      const {
        required,
        packages
      } = insurance;

      if (!required) {
        return {
          ...insurance,
          packages: [
            {
              id: index - 1000,
              name: ADD_INSURANCE_EXTRA_IDS.NO_COVERAGE,
              is_available: null,
              is_default: true,
              price: 0,
              description: null,
              value: null
            },
            ...packages
          ]
        };
      } else {
        return insurance;
      }
    });
  } else {
    return insurances;
  }

};

export const addAdditionalInsurance = (insuranceList, TIIprice, links) => {
  if (TIIprice) {


    const whatsLink = links.find(({ id }) => id === 'tii_whats_cover').link;
    const termsLink = links.find(({ id }) => id === 'tii_terms').link;

    const extraItem = {
      id: ADD_INSURANCE_EXTRA_IDS.INSURANCE_ID,
      name: "Trip Interruption / Interior Protection Insurance",
      required: false,
      multi_select: false,
      description: {
        id: "desc2",
        type: "html",
        value: `<p><a href=\"${whatsLink}\" target=\"_blank\">What’s covered</a><span class=\"dib-m ml-4 mr-4\">|</span><a href=\"${termsLink}\" target=\"_blank\">Terms and Conditions</a></p>`
      },
      packages: [
        {
          id: ADD_INSURANCE_EXTRA_IDS.PACKAGE_ID,
          name: "iTravelInsured",
          is_available: null,
          is_default: true,
          price: TIIprice,
          description: {
            id: "description2",
            type: "html",
            value: ""
          },
          value: {
            id: "value2",
            type: "plain",
            value:`${convertCents(TIIprice)}`
          }
        }
      ],
      tags: [ADD_INSURANCE_DEFAULT_TAGS.BIRTH, ADD_INSURANCE_DEFAULT_TAGS.NAME]
    };

    const form_elements = [
      {
        type: FIELD_TYPES.DATE,
        tag: ADD_INSURANCE_DEFAULT_TAGS.BIRTH,
        label: 'Date of birth'
      },
      {
        type: FIELD_TYPES.TEXT,
        tag: ADD_INSURANCE_DEFAULT_TAGS.NAME,
        label: 'Full name'
      }
    ];

    return {
      ...insuranceList,
      form_elements: [
        ...insuranceList.form_elements,
        ...form_elements
      ],
      insurances: [
        ...addEmptyPackageInInsurances([...insuranceList.insurances, extraItem])
      ]
    };
  } else {
    return  {
      ...insuranceList,
      insurances: [
        ...addEmptyPackageInInsurances([...insuranceList.insurances])
      ]
    };
  }
};

export const getTotalPackagesIds = ({ insurancesIds, packagesIds }, { insurances = [] }) => {
  return insurances.reduce((totalPackagesIds, { id: insuranceId, packages }) => {
    let ids = totalPackagesIds;

    if (insurancesIds.includes(insuranceId)) {
      packages.forEach(({ id: packageId }) => {
        if (packagesIds.includes(packageId)) {
          ids.push(packageId);
        }
      });
    }

    return ids;
  }, []);
};

export const getTotalInsurances = ({ insurancesIds, availablePackagesIds }, { insurances = [] }) => {
  return insurances.reduce((totalInsurances, { id: insuranceId, packages }) => {
    const insurance = {
      id: insuranceId,
      required: insurancesIds.includes(insuranceId),
      available_packages: []
    };

    if (!insurancesIds.includes(insuranceId)) {
      packages.forEach(({ id: packageId }) => insurance.available_packages.push(packageId));
    } else {
      packages.forEach(({ id: packageId }) => {
        if(availablePackagesIds.includes(packageId)) {
          insurance.available_packages.push(packageId);
        }
      });
    }

    totalInsurances.push(insurance);

    return totalInsurances;
  }, []);
};

export const getInitialAdditionalInsuranceData = ({ insurances = [] }, mode = ADD_INSURANCE_LIST_MODES.USING) => {
  const insurancesIds = [];
  const packagesIds = [];
  const availablePackagesIds = [];

  insurances.forEach(({ id: insuranceId, required, packages }, index) => {
    setIds(required, insurancesIds, insuranceId, packages, packagesIds, availablePackagesIds, mode, index);
  });

  return {
    insurancesIds,
    packagesIds,
    ...(mode === ADD_INSURANCE_LIST_MODES.SETTINGS ? { availablePackagesIds: availablePackagesIds } : {})
  };
};

export const mergeAdditionalInsuranceData = ({
  insurancesIds: prevInsurancesIds,
  packagesIds: prevPackagesIds
}, {
  insurances: nextInsurances = []
},
mode = ADD_INSURANCE_LIST_MODES.USING
) => {
  let newInsurancesIds = [];
  let newPackagesIds = [];

  nextInsurances.forEach(({ id: nextInsuranceId, packages, required }) => {
    if (prevInsurancesIds.includes(nextInsuranceId)) {
      newInsurancesIds.push(nextInsuranceId);

      let isSelectedPackage = false;

      packages.forEach(({ id: packageId }) => {
        if (prevPackagesIds.includes(packageId)) {
          newPackagesIds.push(packageId);
          isSelectedPackage = true;
        }
      });

      if (!isSelectedPackage) {
        if (packages?.length === 1) {
          newPackagesIds.push(packages?.[0]?.id);
        } else {
          packages.forEach(({ id: packageId, is_default }) => {
            if (is_default) {
              newPackagesIds.push(packageId);
            }
          });
        }
      }
    } else {
      if (mode === ADD_INSURANCE_LIST_MODES.SETTINGS || required) {
        setIds(required, newInsurancesIds, nextInsuranceId, packages, newPackagesIds);
      } else {
        let isSelectedPackage = false;
        packages.forEach(({ id: packageId }) => {
          if (prevPackagesIds.includes(packageId)) {
            isSelectedPackage = true;
            newPackagesIds.push(packageId);
          }
        });

        if (!isSelectedPackage) {
          packages.forEach(({ id: packageId }) => {
            if (packageId < 0) {
              newPackagesIds.push(packageId);
            }
          });
        }
      }
    }
  });

  return {
    insurancesIds: newInsurancesIds,
    packagesIds: newPackagesIds
  };
};

export const isNextStep = ({ insurancesIds, packagesIds }, { insurances = [] }) => {
  let isNext = true;

  insurances.forEach(({ id: insuranceId, packages }) => {
    if (insurancesIds.includes(insuranceId) && isNext) {
      let isPackage = false;

      packages.forEach(({ id: packageId }) => {
        if (packagesIds.includes(packageId)) {
          isPackage = true;
        }
      });

      if (!isPackage) {
        isNext = false;
      }
    }
  });

  return isNext;
};

export const hasExtraInsurance = ({ insurancesIds }) => {
  return insurancesIds?.includes(ADD_INSURANCE_EXTRA_IDS.INSURANCE_ID);
};

export const getTotalPackages = ({ insurancesIds, packagesIds }, { insurances = [] }) => {
  const ids = [];

  insurances.forEach(({ id: insuranceId, packages }) => {
    if (insurancesIds.includes(insuranceId)) {
      packages.forEach(({ id: packageId }) => {
        if (packagesIds.includes(packageId)) {
          ids.push(packageId);
        }
      });
    }
  });

  return ids;
};

export const isSelectedInsurance = ({ insurancesIds }, { insurances = [] }) => {
  let isDisabled = false;

  insurances.forEach(({ id: insuranceId }) => {
    if (insurancesIds.includes(insuranceId)) {
      isDisabled = true;
    }
  });

  return isDisabled;
};

export const getTotalPrice = ({ insurancesIds, packagesIds }, { insurances = [] }) => {
  return insurances.reduce((sum, { id: insuranceId, packages }) => {
    if (!insurancesIds.includes(insuranceId)) return sum;

    let totalSum = sum;

    packages.forEach(({ id: packageId, price }) => {
      if (packagesIds.includes(packageId)) {
        totalSum += price;
      }
    });

    return totalSum;

  }, 0);
};

export const getItemData = (insuranceId, itemPackages, selectedPackages, insurancesIds, mode = ADD_INSURANCE_LIST_MODES.USING ) => {
  const selectedItemPackages = itemPackages.filter(({ id }) => selectedPackages.includes(id));
  const isItemPackagesDisabled = !insurancesIds.includes(insuranceId) && mode === ADD_INSURANCE_LIST_MODES.SETTINGS;
  const isSwitchChecked = insurancesIds.includes(insuranceId);

  return {
    selectedItemPackages,
    isItemPackagesDisabled,
    isSwitchChecked
  };
};

export const handleSwitchChange = (insuranceId, checked, selectedPackages, callback) => {
  callback({
    ...selectedPackages,
    insurancesIds: checked ?
        [...selectedPackages.insurancesIds, insuranceId]
      : selectedPackages.insurancesIds.filter((id) => id !== insuranceId)
  });
};

export const handleRadioTap = (selectedId, itemPackages, selectedPackages, callback, mode = ADD_INSURANCE_LIST_MODES.USING, insuranceId) => {
  if (!selectedPackages?.packagesIds.includes(selectedId)) {
    const itemPackagesIds = itemPackages.map(({ id }) => id);
    const newSelectedPackagesIds = selectedPackages.packagesIds.filter((packageId) => !itemPackagesIds.includes(packageId));

    if (mode === ADD_INSURANCE_LIST_MODES.SETTINGS) {
      const newAvailablePackagesIds = selectedPackages.availablePackagesIds.filter((availablePackageId) => !itemPackagesIds.includes(availablePackageId));

      for (let i = 0; i < itemPackagesIds.length; i++) {
        if (itemPackagesIds[i] === selectedId) {
          const otherPackages = itemPackagesIds.slice(i);

          otherPackages.forEach((packageId) => newAvailablePackagesIds.push(packageId));

          break;
        }
      }

      callback({
        ...selectedPackages,
        packagesIds: [...newSelectedPackagesIds, selectedId],
        availablePackagesIds: newAvailablePackagesIds
      });
    } else if (mode === ADD_INSURANCE_LIST_MODES.USING) {

      let newInsurancesIds = selectedPackages.insurancesIds;

      if (selectedId < 0) {
        newInsurancesIds = newInsurancesIds.filter(id => insuranceId !== id);
      } else {
        newInsurancesIds.push(insuranceId);
      }

      callback({
        ...selectedPackages,
        insurancesIds: newInsurancesIds,
        packagesIds: [...newSelectedPackagesIds, selectedId]
      });
    }
  }
};

export const handleCheckTap = (selectedId, checked, itemPackages, selectedPackages, callback, mode = ADD_INSURANCE_LIST_MODES.USING) => {
  const itemPackagesIds = itemPackages.map(({ id }) => id);

  if (mode === ADD_INSURANCE_LIST_MODES.SETTINGS) {
    const newAvailablePackagesIds = selectedPackages.availablePackagesIds.filter((availablePackageId) => !itemPackagesIds.includes(availablePackageId));
    const newPackagesIds = checked ? [...selectedPackages.packagesIds, selectedId] : [...selectedPackages.packagesIds.filter((packageId) => selectedId !== packageId)];

    for (let i = 0; i < itemPackagesIds.length; i++) {
      if (newPackagesIds.includes(itemPackagesIds[i])) {
        const otherPackages = itemPackagesIds.slice(i);

        otherPackages.forEach((packageId) => newAvailablePackagesIds.push(packageId));

        break;
      }
    }

    callback({
      ...selectedPackages,
      packagesIds: newPackagesIds,
      availablePackagesIds: newAvailablePackagesIds
    });

  } else if (mode === ADD_INSURANCE_LIST_MODES.USING) {
    callback({
      ...selectedPackages,
      packagesIds: checked ? [...selectedPackages.packagesIds, selectedId] : [...selectedPackages.packagesIds.filter((packageId) => selectedId !== packageId)]
    });
  }
};

